import { DetailedHTMLProps, HTMLAttributes, useEffect, useRef } from 'react';

function ScrollIntoView({
  when = true,
  ...props
}: {
  when?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {

  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (when) {
      const element = elementRef.current;

      if (element) {
        const timer = window.setTimeout(() => {
          const { top } = element.getBoundingClientRect();
          window.scrollTo({ top: top + window.scrollY - 100 });
        });

        return () => {
          window.clearTimeout(timer);
        };
      }
    }
  }, [when]);

  return (
    <div {...props} ref={elementRef}></div>
  );
}

export default ScrollIntoView;
