import { Head, Link, router } from '@inertiajs/react';
import { ReactNode, useState } from 'react';
import { ArrowDown, ArrowUp, Link as LinkIcon, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../Shared/Modal/Modal';
import { Campaign, Event, Pagination, Project } from '../../Shared/types';
import { Icon } from '../../Shared/UI/Icon';
import { Pager } from '../../Shared/UI/Pager';
import { SearchInput } from '../../Shared/UI/SearchInput';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import CampaignCard from '../Campaign/CampaignCard';
import ProjectCard from '../Project/ProjectCard';
import ProjectLogo from '../Project/ProjectLogo';
import { useRouter } from '../router';
import { SharedProps } from '../types';
import { Button } from '../UI/Button';
import Container from '../UI/Container';
import Header from '../UI/Header';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';

export interface CharityPageProps extends SharedProps {
  campaigns: Pagination<Campaign & { project: Project; }>;
  projects: (Project & {
    event: Event | null;
  })[];
}

export default function CharityPage({
  charity,
  campaigns,
  projects,
  auth: { user },
  params,
}: CharityPageProps) {
  const { t } = useTranslation();
  const { routes } = useRouter();

  useCharityStyle(charity);

  const [showRegisterModal, setShowRegisterModal] = useState(!!params.register);

  const projectsLimit = 5;
  const [expandProjects, setExpandProjects] = useState(false);
  const visibleProjects = expandProjects ? projects : projects.slice(0, projectsLimit);
  const openProjects = projects.filter((project) => project.open && !project.ended);

  return (
    <>
      <Head title={charity.title} />

      <NavBar charity={charity} user={user} />

      <Header>
        <Container>
          <div className="space-y-2">
            <h1 className="text-2xl">
              {charity.title}
            </h1>
            {charity.website && (
              <div>
                <a href={charity.website} target="_blank" rel="noopener noreferrer">
                  <Icon className="mr-2">
                    <LinkIcon />
                  </Icon>
                  {charity.website}
                </a>
              </div>
            )}
          </div>
        </Container>
      </Header>

      <Container>
        {charity.description && (
          <div>
            {charity.description}
          </div>
        )}

        <Button
          onClick={() => setShowRegisterModal(true)}
          size="lg"
          className="block w-full !font-bold"
        >
          <Icon className="mr-2">
            <Plus />
          </Icon>
          {t('frontend:start_a_campaign')}
        </Button>

        <Modal isOpen={showRegisterModal} onClose={() => router.visit(routes.charity_page(charity.id))} size="sm">
          <div>
            <h2 className="leading-[32px] mb-2">
              {t('frontend:start_a_campaign')}
            </h2>

            <div className="mb-4">
              {t('frontend:choose_your_project')}
            </div>

            <div className="space-y-2">
              {openProjects.map((project) => (
                <div key={project.id}>
                  <Link
                    href={routes.register_page(project.id)}
                    className="flex items-center gap-3 px-3 py-2 font-medium border rounded-lg"
                  >
                    <ProjectLogo project={project} charity={charity} />
                    <div className="overflow-hidden whitespace-nowrap text-ellipsis">
                      {project.title}
                    </div>
                  </Link>
                </div>
              ))}
              {openProjects.length === 0 && t('frontend:no_projects_yet')}
            </div>
          </div>
        </Modal>

        <hr className="border-slate-200" />

        <div className="space-y-4">
          <h2 className="text-xl" id="campaigns">
            {t('frontend:campaigns')}
          </h2>
          {(params.campaigns_search || campaigns.meta.last_page > 1) && (
            <div>
              <SearchInput name="campaigns" id="campaigns_search" />
            </div>
          )}
          <div className="space-y-2">
            {campaigns.data.map((campaign) => (
              <CampaignCard charity={charity} campaign={campaign} showProject key={campaign.id}/>
            ))}
            {campaigns.meta.total === 0 && (
              <div className="text-slate-500">
                {t('frontend:no_results')}
              </div>
            )}
          </div>
          {campaigns.meta.last_page > 1 && (
            <Pager data={campaigns} />
          )}
        </div>

        <hr className="border-slate-200" />

        <div className="space-y-4">
          <h2 className="text-xl" id="projects">
            {t('frontend:projects')}
          </h2>
          <div className="space-y-2">
            {visibleProjects.map((project) => (
              <ProjectCard project={project} charity={charity} key={project.id}/>
            ))}
            {projects.length === 0 && (
              <div className="text-slate-500">
                {t('frontend:no_projects_yet')}
              </div>
            )}
          </div>
          {projects.length > projectsLimit && (
            <div className="text-center">
              <Button
                onClick={() => setExpandProjects((expand) => !expand)}
                variant="tertiary"
                size="xs"
                className="!font-medium !text-slate-900"
              >
                <Icon className="mr-1.5">
                  {!expandProjects ? <ArrowDown /> : <ArrowUp />}
                </Icon>
                {!expandProjects ? t('frontend:show_all') : t('frontend:show_less')}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

CharityPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
