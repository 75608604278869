import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { setServerErrors, useMutation } from '../../Shared/api';
import { useNotifier } from '../../Shared/Notifier/NotificationProvider';
import { Button } from '../../Shared/UI/Button';
import { Checkbox } from '../../Shared/UI/Checkbox';
import CheckboxLabel from '../../Shared/UI/CheckboxLabel';
import InputGroup from '../../Shared/UI/InputGroup';
import InputLabel from '../../Shared/UI/InputLabel';
import ServerErrors from '../../Shared/UI/ServerErrors';
import { Textarea } from '../../Shared/UI/Textarea';
import { useRouter } from '../router';

export default function DeleteCampaignForm({
  campaign,
  onSuccess,
}: {
  campaign: {
    id: string;
  };
  onSuccess?: () => void;
}) {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const notifier = useNotifier();

  const { register, handleSubmit, setError, formState: { errors, defaultValues } } = useForm({
    defaultValues: {
      reason: null as string | null,
    },
  });

  const [deleteCampaign, { loading, errors: serverErrors }] = useMutation(routes.delete_campaign(campaign.id), {
    onSuccess: () => {
      notifier.notify(t('frontend:campaign_deleted'));
      onSuccess?.();
    },
    onError: (errors) => {
      setServerErrors(errors, setError, defaultValues);
    },
  });

  const submit = async (data: typeof defaultValues) => {
    await deleteCampaign(data);
  };

  const [confirmed, setConfirmed] = useState(false);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="space-y-6">
        <div className="space-y-2">
          <h3 className="py-1">
            {t('frontend:delete_campaign')}
          </h3>
          <p className="text-slate-700">
            {t('frontend:delete_campaign_description')}
          </p>
        </div>

        <hr />

        {serverErrors && <ServerErrors errors={serverErrors} defaultValues={defaultValues} />}

        <InputGroup>
          <InputLabel htmlFor="delete_campaign_reason">
            {t('frontend:delete_campaign_reason_title')}
          </InputLabel>
          <Textarea
            {...register('reason')}
            id="delete_campaign_reason"
            aria-invalid={!!errors.reason}
            autoFocus
            maxLength={255}
          />
        </InputGroup>

        <InputGroup>
          <CheckboxLabel htmlFor="delete_campaign_confirmed">
            <Checkbox
              onChange={() => setConfirmed((confirmed) => !confirmed)}
              checked={confirmed}
              id="delete_campaign_confirmed"
              className="mt-1"
            />
            <span>
              {t('frontend:delete_campaign_confirmation')}
            </span>
          </CheckboxLabel>
        </InputGroup>

        <hr />

        <div className="text-right">
          <Button
            type="submit"
            disabled={loading || !confirmed}
            loading={loading}
            variant="danger"
          >
            {t('frontend:delete')}
          </Button>
        </div>
      </div>
    </form>
  );
}
