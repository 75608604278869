import { Head } from '@inertiajs/react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../Shared/Modal/Modal';
import { AddressFields, Campaign, DateOfBirthFields, PhoneFields, Project } from '../../Shared/types';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { SharedProps } from '../types';
import { Button } from '../UI/Button';
import Container from '../UI/Container';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';
import DeleteCampaignForm from './DeleteCampaignForm';
import { EditCampaignForm } from './EditCampaignForm';
import { UserNav } from './UserNav';

export interface EditCampaignPageProps extends SharedProps {
  campaign: Campaign & AddressFields & DateOfBirthFields & PhoneFields & {
    user_deletable: boolean;
    project: Project;
    team: Campaign | null;
  };
  teams: Campaign[];
}

export default function EditCampaignPage({
  campaign,
  teams,
  charity,
  auth: { user },
  params,
}: EditCampaignPageProps) {
  const { t } = useTranslation();

  useCharityStyle(charity);

  const [showDeleteCampaignModal, setShowDeleteCampaignModal] = useState(false);

  if (!user) {
    return <></>;
  }

  return (
    <>
      <Head title={`${campaign.title} – ${campaign.project.title} – ${charity.title}`} />

      <NavBar charity={charity} user={user} />

      {user && <UserNav campaign={campaign} user={user} />}

      <Container>
        <div className="flex space-x-4 items-center leading-tight">
          {campaign.thumbnail_url && (
            <div className="w-16 h-12">
              <img src={campaign.thumbnail_url} className="w-full h-full object-cover rounded" />
            </div>
          )}
          <div>
            <h2>
              {campaign.title}
            </h2>
            {campaign.project.title}
          </div>
        </div>
        <hr />
        {user && (
          <EditCampaignForm
            campaign={{ ...campaign, user }}
            teams={teams}
            charity={charity}
            scrollToEmailSettings={'unsubscribe' in params}
          />
        )}
        {campaign.user_deletable && (
          <>
            <hr className="border-slate-200" />
            <div className="text-center text-sm">
              <Button
                onClick={() => setShowDeleteCampaignModal(true)}
                variant="inline"
              >
                {t('frontend:delete_campaign')}
              </Button>
            </div>
          </>
        )}
      </Container>

      <Modal isOpen={showDeleteCampaignModal} onClose={() => setShowDeleteCampaignModal(false)} size="sm">
        <DeleteCampaignForm campaign={campaign} />
      </Modal>
    </>
  );
}

EditCampaignPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
