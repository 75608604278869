import { Link } from '@inertiajs/react';
import classNames from 'classnames';
import Vapor from 'laravel-vapor';
import { useContext } from 'react';
import { User as UserIcon, X } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { EventType } from '../../Shared/eventTracking';
import { useLocale } from '../../Shared/locale';
import { Charity, User } from '../../Shared/types';
import { Icon } from '../../Shared/UI/Icon';
import { useRouter } from '../router';
import { AButton, Button } from './Button';
import Container from './Container';
import { EmbedContext } from './EmbedProvider';

export function NavBar({
  charity,
  user,
  showLogin = true,
}: {
  charity?: Charity;
  user?: User | null;
  showLogin?: boolean;
}) {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { isEmbedded } = useContext(EmbedContext);
  const { locale } = useLocale();

  const loginLink = `/login?redirect_url=${encodeURIComponent(window.location.href)}&locale=${locale}&charity_id=${charity?.id}`;

  const closeEmbed = () => {
    window.parent.postMessage({
      type: EventType.WidgetClosed,
    }, '*');
  };

  return (
    <div
      className={classNames(
        'relative bg-white shadow-sm',
        isEmbedded && 'rounded-t-xl',
      )}
    >
      <Container className="!py-3">
        <div className="flex items-center justify-between">
          {charity && (
            <Link
              href={routes.charity_page(charity.id)}
              className={classNames(
                'text-sm font-medium text-slate-800 hover:text-slate-900',
                isEmbedded && 'pointer-events-none'
              )}
              aria-label={charity.title}
            >
              {charity.logo_url && (
                <img src={charity.logo_url} className="h-8 rounded-sm" alt={charity.title} />
              )}
              {!charity.logo_url && (
                <div className="overflow-hidden whitespace-nowrap text-ellipsis py-1.5">
                  {charity.title}
                </div>
              )}
            </Link>
          )}
          {!charity && (
            <img
              src={Vapor.asset('images/logo.svg')}
              alt={'Supporta'}
              className="h-3.5"
            />
          )}
          <div className="flex items-center space-x-4">
            {showLogin && user && (
              <AButton
                href={routes.dashoard_page()}
                variant="tertiary"
                size="sm"
                className="hover:bg-slate-100 text-slate-800 hover:text-slate-800 whitespace-nowrap !px-2"
              >
                <Icon className="mr-2 ">
                  <UserIcon />
                </Icon>
                {user.first_name}
              </AButton>
            )}

            {showLogin && !user && (
              <AButton
                href={loginLink}
                variant="tertiary"
                size="sm"
                className="!text-slate-800 hover:bg-slate-100 !px-2"
                target={isEmbedded ? '_blank' : undefined}
                rel={isEmbedded ? 'noreferrer noopener' : undefined}
              >
                <Icon className="mr-2">
                  <UserIcon />
                </Icon>
                {t('frontend:log_in')}
              </AButton>
            )}

            {isEmbedded && (
              <Button
                onClick={() => closeEmbed()}
                iconButton
                variant="tertiary"
                size="sm"
                className="!text-2xl text-slate-400 hover:bg-slate-100"
                aria-label={t('frontend:close')}
              >
                <Icon>
                  <X strokeWidth={2} />
                </Icon>
              </Button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
