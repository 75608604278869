import { InertiaLinkProps, Link } from '@inertiajs/react';
import classNames from 'classnames';

export const breadcrumbClasses = 'inline-block text-sm font-medium rounded text-slate-800 hover:text-slate-900 overflow-hidden whitespace-nowrap text-ellipsis bg-project/10 px-2 py-1 rounded';

export default function Breadcrumb({ children, ...props }: InertiaLinkProps) {
  const className = classNames(
    breadcrumbClasses,
    props.className
  );

  return (
    <div className="flex min-w-0">
      <Link {...props} className={className}>
        {children}
      </Link>
    </div>
  );
}
