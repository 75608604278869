import { Head } from '@inertiajs/react';
import classNames from 'classnames';
import { ChangeEvent, ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { ChevronDown, Eye, EyeOff, Image, Plus, Trash, X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useMutation, useServerErrors } from '../../Shared/api';
import { Editor } from '../../Shared/Editor/Editor';
import { useLocale } from '../../Shared/locale';
import { useNotifier } from '../../Shared/Notifier/NotificationProvider';
import { Campaign as BaseCampaign, Order, Product, Project, User } from '../../Shared/types';
import Address from '../../Shared/UI/Address';
import { Card } from '../../Shared/UI/Card';
import { Checkbox } from '../../Shared/UI/Checkbox';
import CheckboxLabel from '../../Shared/UI/CheckboxLabel';
import { ConfirmButton } from '../../Shared/UI/ConfirmButton';
import ErrorMessage from '../../Shared/UI/ErrorMessage';
import { FadeIn } from '../../Shared/UI/FadeIn';
import { FileInput } from '../../Shared/UI/FileInput';
import { HTML } from '../../Shared/UI/HTML';
import { Icon } from '../../Shared/UI/Icon';
import { Input } from '../../Shared/UI/Input';
import InputDescription from '../../Shared/UI/InputDescription';
import InputGroup from '../../Shared/UI/InputGroup';
import InputLabel from '../../Shared/UI/InputLabel';
import { InputWrapper } from '../../Shared/UI/InputWrapper';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { useCurrencyInput } from '../../Shared/useCurrencyInput';
import { useIntegerInput } from '../../Shared/useIntegerInput';
import { useRouter } from '../router';
import { SharedProps } from '../types';
import { Button } from '../UI/Button';
import Container from '../UI/Container';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';
import { UserNav } from './UserNav';

interface Campaign extends BaseCampaign {
  project: Project;
  active_products: Product[];
  optional_products: Product[];
  orders: Order[];
  user: User;
}

export interface CampaignProductsPageProps extends SharedProps {
  campaign: Campaign;
}

export default function CampaignProductsPage({
  campaign,
  charity,
  auth: { user },
}: CampaignProductsPageProps) {
  const { t } = useTranslation();

  useCharityStyle(charity);

  if (!campaign.user || !user) {
    return <></>;
  }

  return (
    <>
      <Head title={`${campaign.title} – ${campaign.project.title} – ${charity.title}`} />

      <NavBar charity={charity} user={user} />

      {user && <UserNav campaign={campaign} user={user} />}

      <Container>
        <div className="flex space-x-4 items-center leading-tight">
          {campaign.thumbnail_url && (
            <div className="w-16 h-12">
              <img src={campaign.thumbnail_url} className="w-full h-full object-cover rounded" />
            </div>
          )}
          <div>
            <h2>
              {campaign.title}
            </h2>
            {campaign.project.title}
          </div>
        </div>
        <hr />
        <div className="space-y-4">
          <div>
            <h4 className="font-medium text-slate-500">
              {t('frontend:sell_products', {
                charity: charity.title,
              })}
            </h4>
            <p>
              {t('frontend:sell_products_description', {
                user: user.first_name,
                charity: charity.title,
              })}
            </p>
          </div>

          <div className="space-y-2">
            {campaign.optional_products.map((product) => (
              <ProductCard campaign={campaign} product={product} key={product.id} />
            ))}

            <AddProductCard campaign={campaign} />
          </div>
        </div>

        <hr className="border-slate-200" />

        <div className="space-y-4">
          <div>
            <h4 className="font-medium text-slate-500">
              {t('frontend:your_orders')}
            </h4>
            <p>
              {t('frontend:your_orders_description')}
            </p>
          </div>

          {campaign.orders.length > 0 && (
            <div className="space-y-2">
              {campaign.orders.map((order) => (
                <OrderCard order={order} key={order.id} />
              ))}
            </div>
          )}

          {campaign.orders.length === 0 && (
            <div className="text-slate-500">
              {t('frontend:no_results')}
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

const ProductCard = ({
  campaign,
  product,
}: {
  campaign: Campaign;
  product: Product;
}) => {
  const { formatCurrency } = useLocale();

  const [expanded, setExpanded] = useState(false);

  const isEnabled = campaign.active_products.filter(({ id }) => product.id === id).length > 0;

  const soldCount = product.is_campaign_product ? (product.sold_count || 0) : campaign.orders.reduce(
    (sum, order) => sum + order.items.reduce(
      (sum, item) => sum + (item.product.id === product.id ? item.quantity : 0),
      0,
    ),
    0,
  );

  return (
    <Card
      link={!expanded ? {
        onClick: () => setExpanded(true),
        ariaLabel: product.title,
      } : undefined}
      spacing={!expanded ? 'xs' : 'sm'}
      border
    >
      <div className="space-y-6">
        <div className="grid grid-cols-[40px_1fr_32px] items-center gap-3">
          <div className={classNames('w-10 h-10 rounded', !product.image_url && 'bg-project/20')}>
            {product.image_url && (
              <img src={product.image_url} alt={product.title} className="block object-cover rounded w-full h-full" />
            )}
          </div>
          <div className="leading-tight">
            <strong>
              {product.title}
            </strong>
            <div className={classNames('flex justify-between', !isEnabled && 'text-slate-400')}>
              <span>
                {formatCurrency(product.selling_price)}
                <Icon className="ml-1 text-sm mr-3">
                  {isEnabled ? <Eye /> : <EyeOff /> }
                </Icon>
              </span>
            </div>
          </div>
          <div>
            <Button
              onClick={() => setExpanded((expanded) => !expanded)}
              variant="tertiary"
              size="sm"
              className="!p-0.5"
            >
              {expanded ? (
                <X strokeWidth={1.5} className="block w-6 h-6" />
              ) : (
                <ChevronDown strokeWidth={1.5} className="block w-6 h-6" />
              )}
            </Button>
          </div>
        </div>
        <FadeIn
          when={expanded}
          render={() => product.is_campaign_product
            ? <CampaignProductForm campaign={campaign} product={product} soldCount={soldCount} />
            : <GlobalProductForm campaign={campaign} product={product} soldCount={soldCount} />}
        />
      </div>
    </Card>
  );
};

const GlobalProductForm = ({
  campaign,
  product,
  soldCount,
}: {
  campaign: Campaign;
  product: Product;
  soldCount: number;
}) => {
  const { t } = useTranslation();
  const { formatCurrency, formatNumber } = useLocale();
  const { routes } = useRouter();
  const notifier = useNotifier();

  const isEnabled = campaign.active_products.filter(({ id }) => product.id === id).length > 0;

  const [toggleProduct, { loading }] = useMutation(routes.toggle_product(campaign.id, product.id), {
    data: {
      enabled: !isEnabled,
    },
    preserveScroll: true,
    onSuccess: () => {
      notifier.notify(
        !isEnabled ? t('frontend:product_enabled') : t('frontend:product_disabled'),
        !isEnabled ? Eye : EyeOff
      );
    },
  });

  return (
    <div className="space-y-6">
      <hr />
      {product.selling_price > product.cost_price && (
        <div className="text-emerald-600">
          {t('frontend:campaigner_cost_price_description', {
            donationAmount: formatCurrency(product.selling_price - product.cost_price),
          })}
        </div>
      )}
      {product.campaigner_description && (
        <HTML value={product.campaigner_description} />
      )}
      <hr />
      <div className="flex justify-between items-center">
        <div className="text-slate-500 font-medium">
          {t('frontend:times_sold', {
            value: formatNumber(soldCount),
            count: soldCount,
          })}
        </div>
        <Button
          onClick={() => toggleProduct()}
          variant={isEnabled ? 'secondary' : 'primary'}
          loading={loading}
          disabled={loading}
          className="ml-auto"
        >
          <Icon className="mr-1">
            {isEnabled ? <EyeOff /> : <Eye />}
          </Icon>
          {isEnabled ? t('frontend:disable') : t('frontend:enable')}
        </Button>
      </div>
    </div>
  );
};

const CampaignProductForm = ({
  campaign,
  product,
  soldCount,
}: {
  campaign: Campaign;
  product: Product;
  soldCount: number;
}) => {
  return (
    <div className="space-y-6">
      <hr />
      <EditProductForm campaign={campaign} product={product} soldCount={soldCount} />
    </div>
  );
};

const EditProductForm = ({
  campaign,
  product,
  soldCount,
}: {
  campaign: Campaign;
  product: Product;
  soldCount: number;
}) => {
  const { t } = useTranslation();
  const { formatNumber } = useLocale();
  const { handleCurrencyBlur, parseCurrency, formatCurrency } = useCurrencyInput();
  const { handleIntegerBlur, parseInteger, formatInteger } = useIntegerInput();
  const { routes } = useRouter();
  const notifier = useNotifier();

  const form = useForm({
    defaultValues: {
      title: product.title,
      selling_price: formatCurrency(product.selling_price),
      public_description: product.public_description,
      capacity: typeof product.capacity === 'number' ? formatInteger(product.capacity) : '',
      image: null,
      delete_image: false,
      enabled: product.enabled,
    },
  });

  const {
    register, handleSubmit, setValue, resetField, setError, control, watch,
    formState: { errors, defaultValues },
  } = form;

  const [editProduct, { loading: editing, errors: serverErrors }] = useMutation(
    routes.edit_product(campaign.id, product.id), {
      preserveScroll: true,
      onSuccess: () => {
        notifier.notify(t('frontend:product_saved'));
      },
    }
  );

  const [deleteProduct, { loading: deleting }] = useMutation(
    routes.delete_product(campaign.id, product.id),
    {
      preserveScroll: true,
      onSuccess: () => {
        notifier.notify(t('frontend:product_deleted'));
      },
    }
  );

  useServerErrors(form, serverErrors);

  const loading = editing || deleting;

  const currentImageUrl = product.image_url;
  const [image, setImage] = useState<File>();
  const deleteImage = watch('delete_image');
  const canDeleteImage = !deleteImage && (!!image || !!product.image_url);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setImage(event.target.files?.[0] || undefined);
    resetField('image'); // Reset errors
    setValue('delete_image', false, { shouldDirty: true });
    setImageSrc(undefined);
  };

  const unsetImage = () => {
    setImage(undefined);
    resetField('image'); // Reset errors
    setValue('delete_image', true, { shouldDirty: true });
    setImageSrc(undefined);
  };

  const [imageSrc, setImageSrc] = useState<string>();

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageSrc(reader.result?.toString() || undefined));
      reader.readAsDataURL(image);
    }
  }, [image]);

  const validateImage = (event: SyntheticEvent<HTMLImageElement>) => {
    const element = event.currentTarget;

    if (element.naturalWidth < 300) {
      unsetImage();
      setError('image', { message: t('shared:validation.image_too_small') });
    }
  };

  const submit = async (data: typeof defaultValues) => {
    await editProduct({
      ...data,
      selling_price: data?.selling_price ? parseCurrency(data.selling_price) : null,
      capacity: data?.capacity ? parseInteger(data.capacity) : null,
      image,
      delete_image: data?.delete_image,
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="space-y-6">
      <InputGroup>
        <InputLabel required valid={!errors.title} htmlFor={`edit_product_title_${product.id}`}>
          {t('frontend:product_title')}
        </InputLabel>
        <InputDescription>
          {t('frontend:product_title_description')}
        </InputDescription>
        <Input
          {...register('title', { required: true })}
          id={`edit_product_title_${product.id}`}
          className="mr-4"
          aria-invalid={!!errors.title}
        />
        <ErrorMessage attribute={t('frontend:product_title')} error={errors.title }/>
      </InputGroup>

      <InputGroup>
        <InputLabel required valid={!errors.title} htmlFor={`edit_product_selling_price_${product.id}`}>
          {t('frontend:selling_price')}
        </InputLabel>
        <InputDescription>
          {t('frontend:selling_price_description')}
        </InputDescription>
        <InputWrapper>
          <label htmlFor={`edit_product_selling_price_${product.id}`}>
            €
          </label>
          <Input
            {...register('selling_price', { required: true })}
            id={`edit_product_selling_price_${product.id}`}
            className="pl-7"
            onBlur={(event) => {
              handleCurrencyBlur(event);
              setValue('selling_price', event.target.value);
            }}
            aria-invalid={!!errors.selling_price}
            inputMode="decimal"
          />
        </InputWrapper>
        <ErrorMessage error={errors.selling_price} attribute={t('frontend:selling_price')} />
      </InputGroup>

      <InputGroup>
        <InputLabel valid={!errors.capacity} htmlFor={`edit_product_capacity_${product.id}`}>
          {t('frontend:max_number_of_purchases')}
        </InputLabel>
        <InputDescription>
          {t('frontend:product_capacity_description')}
        </InputDescription>
        <Input
          {...register('capacity')}
          id={`edit_product_capacity_${product.id}`}
          onBlur={(event) => {
            handleIntegerBlur(event);
            setValue('capacity', event.target.value);
          }}
          aria-invalid={!!errors.capacity}
          inputMode="decimal"
        />
        <ErrorMessage error={errors.capacity} attribute={t('frontend:capacity')} />
      </InputGroup>

      <InputGroup>
        <InputLabel htmlFor={`edit_product_image_${product.id}`}>
          {t('frontend:image')}
        </InputLabel>
        <InputDescription>
          {t('frontend:product_image_description')}
        </InputDescription>
        <div className="p-4 border rounded-md border-slate-250">
          <div
            className="flex items-center bg-slate-50 rounded-sm justify-center transition-all w-[150px] aspect-square mb-4"
          >
            {!image && !deleteImage && currentImageUrl && (
              <img
                src={currentImageUrl}
                alt={product.title}
                className="w-full h-full object-cover rounded-sm"
              />
            )}
            {image && imageSrc && (
              <img
                src={imageSrc}
                alt={product.title}
                className="w-full h-full object-cover rounded-sm"
                onLoad={validateImage}
              />
            )}
            {((!image && !currentImageUrl) || deleteImage) && (
              <Icon className="!w-12 !h-12 text-slate-250">
                <Image strokeWidth={1} />
              </Icon>
            )}
          </div>
          <div className="flex space-x-2">
            <FileInput
              {...register('image')}
              accept=".jpg,.jpeg,.png"
              id={`edit_product_image_${product.id}`}
              aria-invalid={!!errors.image}
              onChange={handleImageChange}
            >
              <Icon className="mr-1">
                <Image />
              </Icon>
              {t('shared:form.select')}
            </FileInput>
            {canDeleteImage && (
              <Button onClick={() => unsetImage()} variant="tertiary" size="sm">
                <Icon className="mr-1">
                  <Trash />
                </Icon>
                {t('shared:form.delete')}
              </Button>
            )}
          </div>
        </div>
        <ErrorMessage error={errors.image} attribute={t('frontend:image')} />
      </InputGroup>

      <InputGroup>
        <InputLabel htmlFor={`edit_product_public_description_${product.id}`}>
          {t('frontend:product_description')}
        </InputLabel>
        <InputDescription>
          {t('frontend:product_description_description')}
        </InputDescription>
        <Controller
          control={control}
          name="public_description"
          render={({ field: { onChange, onBlur, value } }) => (
            <Editor
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              hideToolbar
            />
          )}
        />
        <ErrorMessage error={errors.public_description} attribute={t('frontend:product_description')} />
      </InputGroup>

      <InputGroup>
        <CheckboxLabel htmlFor={`edit_product_enabled_${product.id}`}>
          <Checkbox
            {...register('enabled')}
            className="mt-1"
            id={`edit_product_enabled_${product.id}`}
          />
          <span>
            {t('frontend:product_is_enabled')}
          </span>
        </CheckboxLabel>
      </InputGroup>

      <hr />

      <div className="flex justify-between items-center">
        {product.deletable ? (
          <ConfirmButton
            onClick={() => deleteProduct()}
            variant="inline"
            disabled={loading}
            loading={loading}
          >
            <Icon className="mr-1">
              <Trash />
            </Icon>
            {t('frontend:delete')}
          </ConfirmButton>
        ) : (
          <div className="text-slate-500 font-medium">
            {t('frontend:times_sold', {
              value: formatNumber(soldCount),
              count: soldCount,
            })}
          </div>
        )}
        <Button
          type="submit"
          disabled={loading}
          loading={loading}
          className="ml-auto"
        >
          {t('frontend:save')}
        </Button>
      </div>
    </form>
  );
};

const AddProductCard = ({
  campaign,
}: {
  campaign: Campaign;
}) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  return (
    <Card
      link={!expanded ? {
        onClick: () => setExpanded(true),
        ariaLabel: t('frontend:add_product'),
      } : undefined}
      spacing={!expanded ? 'xs' : 'sm'}
      border
    >
      <div className="space-y-6">
        <div className="grid grid-cols-[1fr_32px] items-center gap-3">
          <div className="text-slate-500 font-medium">
            {t('frontend:add_product')}
          </div>
          <div className="-my-3">
            <Button
              onClick={() => setExpanded((expanded) => !expanded)}
              variant="tertiary"
              size="sm"
              className="!p-0.5"
            >
              {expanded ? (
                <X strokeWidth={1.5} className="block w-6 h-6" />
              ) : (
                <Plus strokeWidth={1.5} className="block w-6 h-6" />
              )}
            </Button>
          </div>
        </div>
        <FadeIn
          when={expanded}
          render={() => (
            <div className="space-y-6">
              <hr />
              <AddProductForm campaign={campaign} onSuccess={() => setExpanded(false)} />
            </div>
          )}
        />
      </div>
    </Card>
  );
};

const AddProductForm = ({
  campaign,
  onSuccess,
}: {
  campaign: Campaign;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const { handleCurrencyBlur, parseCurrency } = useCurrencyInput();
  const { handleIntegerBlur, parseInteger } = useIntegerInput();
  const { routes } = useRouter();
  const notifier = useNotifier();

  const form = useForm({
    defaultValues: {
      title: '',
      selling_price: '',
      public_description: '',
      image: null,
      capacity: '',
      enabled: true,
    },
  });

  const {
    register, handleSubmit, setValue, resetField, setError, control,
    formState: { errors, defaultValues },
  } = form;

  const [addProduct, { loading, errors: serverErrors }] = useMutation(
    routes.add_product(campaign.id), {
      onSuccess: () => {
        notifier.notify(t('frontend:product_added'));
        onSuccess();
      },
    });

  useServerErrors(form, serverErrors);

  const [image, setImage] = useState<File>();

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setImage(event.target.files?.[0] || undefined);
    resetField('image'); // Reset errors
    setImageSrc(undefined);
  };

  const unsetImage = () => {
    setImage(undefined);
    resetField('image'); // Reset errors
    setImageSrc(undefined);
  };

  const [imageSrc, setImageSrc] = useState<string>();

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageSrc(reader.result?.toString() || undefined));
      reader.readAsDataURL(image);
    }
  }, [image]);

  const validateImage = (event: SyntheticEvent<HTMLImageElement>) => {
    const element = event.currentTarget;

    if (element.naturalWidth < 300) {
      unsetImage();
      setError('image', { message: t('shared:validation.image_too_small') });
    }
  };

  const submit = async (data: typeof defaultValues) => {
    await addProduct({
      ...data,
      selling_price: data?.selling_price ? parseCurrency(data.selling_price) : null,
      capacity: data?.capacity ? parseInteger(data.capacity) : null,
      image,
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="space-y-6">
      <InputGroup>
        <InputLabel required valid={!errors.title} htmlFor="add_product_title">
          {t('frontend:product_title')}
        </InputLabel>
        <InputDescription>
          {t('frontend:product_title_description')}
        </InputDescription>
        <Input
          {...register('title', { required: true })}
          id="add_product_title"
          aria-invalid={!!errors.title}
        />
        <ErrorMessage attribute={t('frontend:product_title')} error={errors.title }/>
      </InputGroup>

      <InputGroup>
        <InputLabel required valid={!errors.title} htmlFor="add_product_selling_price">
          {t('frontend:selling_price')}
        </InputLabel>
        <InputDescription>
          {t('frontend:selling_price_description')}
        </InputDescription>
        <InputWrapper>
          <label htmlFor="add_product_selling_price">
            €
          </label>
          <Input
            {...register('selling_price', { required: true })}
            id="add_product_selling_price"
            className="pl-7"
            onBlur={(event) => {
              handleCurrencyBlur(event);
              setValue('selling_price', event.target.value);
            }}
            aria-invalid={!!errors.selling_price}
            inputMode="decimal"
          />
        </InputWrapper>
        <ErrorMessage error={errors.selling_price} attribute={t('frontend:selling_price')} />
      </InputGroup>

      <InputGroup>
        <InputLabel valid={!errors.capacity} htmlFor="add_product_capacity">
          {t('frontend:max_number_of_purchases')}
        </InputLabel>
        <InputDescription>
          {t('frontend:product_capacity_description')}
        </InputDescription>
        <Input
          {...register('capacity')}
          id="add_product_capacity"
          onBlur={(event) => {
            handleIntegerBlur(event);
            setValue('capacity', event.target.value);
          }}
          aria-invalid={!!errors.capacity}
          inputMode="decimal"
        />
        <ErrorMessage error={errors.capacity} attribute={t('frontend:capacity')} />
      </InputGroup>

      <InputGroup>
        <InputLabel htmlFor="add_product_image">
          {t('frontend:image')}
        </InputLabel>
        <InputDescription>
          {t('frontend:product_image_description')}
        </InputDescription>
        <div className="p-4 border rounded-md border-slate-250">
          <div
            className="flex items-center bg-slate-50 rounded-sm justify-center transition-all w-[150px] aspect-square mb-4"
          >
            {image && imageSrc && (
              <img
                src={imageSrc}
                className="w-full h-full object-cover rounded-sm"
                onLoad={validateImage}
              />
            )}
            {!image && (
              <Icon className="!w-12 !h-12 text-slate-250">
                <Image strokeWidth={1} />
              </Icon>
            )}
          </div>
          <div className="flex space-x-2">
            <FileInput
              {...register('image')}
              accept=".jpg,.jpeg,.png"
              id="add_product_image"
              aria-invalid={!!errors.image}
              onChange={handleImageChange}
            >
              <Icon className="mr-1">
                <Image />
              </Icon>
              {t('shared:form.select')}
            </FileInput>
            {image && (
              <Button onClick={() => unsetImage()} variant="tertiary" size="sm">
                <Icon className="mr-1">
                  <Trash />
                </Icon>
                {t('shared:form.delete')}
              </Button>
            )}
          </div>
        </div>
        <ErrorMessage error={errors.image} attribute={t('frontend:image')} />
      </InputGroup>

      <InputGroup>
        <InputLabel htmlFor="add_product_public_description">
          {t('frontend:product_description')}
        </InputLabel>
        <InputDescription>
          {t('frontend:product_description_description')}
        </InputDescription>
        <Controller
          control={control}
          name="public_description"
          render={({ field: { onChange, onBlur, value } }) => (
            <Editor
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              hideToolbar
            />
          )}
        />
        <ErrorMessage error={errors.public_description} attribute={t('frontend:product_description')} />
      </InputGroup>

      <InputGroup>
        <CheckboxLabel htmlFor="add_product_enabled">
          <Checkbox
            {...register('enabled')}
            className="mt-1"
            id="add_product_enabled"
          />
          <span>
            {t('frontend:product_is_enabled')}
          </span>
        </CheckboxLabel>
      </InputGroup>

      <hr />

      <div className="flex justify-end space-x-4">
        <Button
          type="submit"
          disabled={loading}
          loading={loading}
        >
          {t('frontend:add_product')}
        </Button>
      </div>
    </form>
  );
};

const OrderCard = ({
  order,
}: {
  order: Order;
}) => {
  const { t } = useTranslation();
  const { formatCurrency, formatDate, formatNumber } = useLocale();

  const [expanded, setExpanded] = useState(false);

  const items = order.items.map((item) => `${formatNumber(item.quantity)}× ${item.product.title}`);

  return (
    <Card
      link={!expanded ? {
        onClick: () => setExpanded(true),
        ariaLabel: order.company_name || order.full_name,
      } : undefined}
      spacing={!expanded ? 'xs' : 'sm'}
      border
    >
      <div className="space-y-6">
        <div className="grid grid-cols-[1fr_fit-content(120px)_32px] items-center gap-3">
          <div>
            <div className="flex justify-between items-end">
              <h4 className="font-bold">
                {order.company_name || order.full_name}
              </h4>
            </div>
            {items.join(', ')}
          </div>
          <div className="text-slate-400 text-sm">
            {formatDate(order.created_at)}
          </div>
          <div>
            <Button
              onClick={() => setExpanded((expanded) => !expanded)}
              variant="tertiary"
              size="sm"
              className="!p-0.5"
            >
              {expanded ? (
                <X strokeWidth={1.5} className="block w-6 h-6" />
              ) : (
                <ChevronDown strokeWidth={1.5} className="block w-6 h-6" />
              )}
            </Button>
          </div>
        </div>
        <FadeIn
          when={expanded}
          render={() => (
            <div className="space-y-6 text-sm">
              <hr />
              <table className="w-full [&_td]:align-top [&_td]:py-1">
                <tbody>
                  <tr>
                    <td className="w-1/3 font-medium text-slate-400">
                      {t('frontend:name')}
                    </td>
                    <td>
                      {order.full_name}
                    </td>
                  </tr>
                  {order.company_name && (
                    <tr>
                      <td className="w-1/3 font-medium text-slate-400">
                        {t('frontend:company_name')}
                      </td>
                      <td>
                        {order.company_name}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="w-1/3 font-medium text-slate-400">
                      {t('frontend:email_address')}
                    </td>
                    <td>
                      {order.email}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-1/3 font-medium text-slate-400">
                      {t('frontend:address')}
                    </td>
                    <td>
                      <Address {...order} />
                    </td>
                  </tr>
                  <tr>
                    <td className="w-1/3 font-medium text-slate-400">
                      {t('frontend:donation')}
                    </td>
                    <td>
                      {formatCurrency(order.amount)}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-1/3 font-medium text-slate-400">
                      {t('frontend:products')}
                    </td>
                    <td>
                      {items.map((item, index) => (
                        <div key={index}>
                          {item}
                        </div>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-1/3 font-medium text-slate-400">
                      {t('frontend:date')}
                    </td>
                    <td>
                      {formatDate(order.created_at, 'display_date_time')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        />
      </div>
    </Card>
  );
};

CampaignProductsPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
