import { EyeOff } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';

export default function PageOfflineWarning() {
  const { t } = useTranslation();

  return (
    <Card shadow={false} spacing="xs" className="border-2 border-slate-300">
      <Icon className="mr-2">
        <EyeOff />
      </Icon>
      {t('frontend:page_is_offline')}
    </Card>
  );
}
