import { useEffect } from 'react';

import { EventType, NewCampaignPayload, NewDonationPayload } from './eventTracking';
import { useEventHandler } from './useEventHandler';

export function useGoogleAnalytics(measurementId: string | null) {
  useEffect(() => {
    if (measurementId) {
      window.dataLayer = window.dataLayer || [];

      window.gtag = function () {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer?.push(arguments);
      };

      // Load with consents that don't require permission from the user.
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'granted',
      });

      window.gtag('js', new Date());
      window.gtag('config', measurementId);

      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
      document.head.insertBefore(script, document.head.childNodes[0]);

      return () => {
        document.head.removeChild(script);
      };
    }
  }, [measurementId]);

  useEventHandler(EventType.NewCampaign, (data: object) => {
    const payload = data as NewCampaignPayload;

    window.gtag?.('event', 'supporta_new_campaign', {
      charity: payload.charity.title,
      project: payload.project.title,
    });
  });

  useEventHandler(EventType.NewDonation, (data: object) => {
    const payload = data as NewDonationPayload;

    // Clear the previous ecommerce object to prevent multiple ecommerce events on a page from affecting each other.
    window.dataLayer?.push({ ecommerce: null });

    window.gtag?.('event', 'purchase', {
      transaction_id: payload.donation.id,
      currency: 'EUR',
      value: payload.donation.amount / 100,
      items: [{
        item_id: `donations_${payload.project.id}`,
        item_name: `Donations | ${payload.project.title}`,
        item_brand: payload.charity.title,
        item_category: 'Donations',
        currency: 'EUR',
        price: payload.donation.amount / 100,
        affiliation: 'Supporta',
      }],
    });
  });
}
