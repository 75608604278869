export const getPaymentMethodLogo = (paymentMethod: string) => `https://www.mollie.com/external/icons/payment-methods/${paymentMethod}.svg`;

export const supportsApplePay = () => {
  let supported = false;

  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).ApplePaySession?.canMakePayments()) {
      supported = true;
    }
  } catch (error) {
    //
  }

  return supported;
};