import { Head } from '@inertiajs/react';
import classNames from 'classnames';
import { ChangeEvent, Fragment, ReactNode, useContext, useState } from 'react';
import { HelpCircle, Lock, Minus, Plus } from 'react-feather';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { setServerErrors, useMutation } from '../../Shared/api';
import { useLocale } from '../../Shared/locale';
import { getPaymentMethodLogo, supportsApplePay } from '../../Shared/paymentMethods';
import { Campaign, Event, PaymentMethod, PaymentMethodType, Product, Project } from '../../Shared/types';
import AddressInputs from '../../Shared/UI/AddressInputs';
import { Checkbox } from '../../Shared/UI/Checkbox';
import CheckboxLabel from '../../Shared/UI/CheckboxLabel';
import Delimit from '../../Shared/UI/Delimit';
import ErrorMessage from '../../Shared/UI/ErrorMessage';
import { FadeIn } from '../../Shared/UI/FadeIn';
import { HTML } from '../../Shared/UI/HTML';
import { Icon } from '../../Shared/UI/Icon';
import InfoMessage from '../../Shared/UI/InfoMessage';
import { Input } from '../../Shared/UI/Input';
import InputDescription from '../../Shared/UI/InputDescription';
import InputGroup from '../../Shared/UI/InputGroup';
import InputLabel from '../../Shared/UI/InputLabel';
import { InputWrapper } from '../../Shared/UI/InputWrapper';
import { Radio } from '../../Shared/UI/Radio';
import { RadioBox } from '../../Shared/UI/RadioBox';
import { Select } from '../../Shared/UI/Select';
import ServerErrors from '../../Shared/UI/ServerErrors';
import { Textarea } from '../../Shared/UI/Textarea';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { useCurrencyInput } from '../../Shared/useCurrencyInput';
import { useIntegerInput } from '../../Shared/useIntegerInput';
import { useRouter } from '../router';
import { SharedProps } from '../types';
import Breadcrumb from '../UI/Breadcrumb';
import { Button } from '../UI/Button';
import Container from '../UI/Container';
import { EmbedContext } from '../UI/EmbedProvider';
import Header from '../UI/Header';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';
import PageOfflineWarning from '../UI/PageOfflineWarning';

interface DonationPageProps extends SharedProps{
  campaign: Campaign & {
    project: Project & {
      event: Event | null;
    };
    active_products: Product[];
    document_title: string;
  };
  paymentMethods: PaymentMethod[];
  continueUrl: string|null;
}

export default function DonationPage({
  campaign,
  paymentMethods,
  charity,
  params,
}: DonationPageProps) {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { formatCurrency, formatNumber, defaultCountry } = useLocale();
  const { handleCurrencyBlur, parseCurrency, formatCurrency: formatInputCurrency } = useCurrencyInput();
  const { handleIntegerBlur, parseInteger } = useIntegerInput();
  const { embedUrl } = useContext(EmbedContext);

  useCharityStyle(charity);

  const supportedPaymentMethods = paymentMethods.filter((paymentMethod) => (
    paymentMethod.id !== PaymentMethodType.APPLE_PAY || supportsApplePay()
  ));
  const canReceivePayments = supportedPaymentMethods.length > 0;

  const form = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      hide_name: false,
      company_name: '',
      email: '',
      country: defaultCountry,
      street: '',
      house_number: '',
      extra_address_line: '',
      zip_code: '',
      city: '',
      email_opt_in: false,
      message: '',
      amount: '',
      payment_method: supportedPaymentMethods.length === 1 ? supportedPaymentMethods[0].id : null,
      payment_method_issuer: null,
      agree_to_terms: false,
    },
  });

  const {
    register, setValue, handleSubmit, setError, watch,
    formState: { errors, defaultValues },
  } = form;

  const [productQuantities, setProductQuantities] = useState<{ [productId: string]: number }>(
    typeof params.product === 'string' ? {
      [params.product]: 1,
    } : {}
  );

  const activeProducts = campaign.active_products.filter((product) => !product.required);
  const requiredProducts = campaign.active_products.filter((product) => product.required);
  const selectedProducts = activeProducts.filter((product) => productQuantities[product.id] > 0);
  const productWithTerms = [...selectedProducts, ...requiredProducts].filter((product) => product.terms_url !== null);

  const totalProductsQuantity = selectedProducts.reduce((quantity, product) => quantity + productQuantities[product.id] || 0, 0);
  const requirePersonalDetails = totalProductsQuantity > 0;
  const shareDetailsWithCampaigner = [...selectedProducts, ...requiredProducts].filter((product) => product.require_opt_in).length > 0;

  const addProduct = (product: Product) => {
    setProductQuantities((productQuantities) => ({
      ...productQuantities,
      [product.id]: Math.min((productQuantities[product.id] || 0) + 1, product.max_per_order),
    }));
  };

  const removeProduct = (product: Product) => {
    setProductQuantities((productQuantities) => ({
      ...productQuantities,
      [product.id]: Math.max((productQuantities[product.id] || 0) - 1, 0),
    }));
  };

  const handleProductQuantityChange = (event: ChangeEvent<HTMLInputElement>, product: Product) => {
    const quantity = parseInteger(event.target.value) || 0;

    setProductQuantities((productQuantities) => ({
      ...productQuantities,
      [product.id]: Math.min(quantity, product.max_per_order),
    }));
  };

  const [isCompany, setIsCompany] = useState(false);
  const toggleCompany = () => setIsCompany((isCompany) => !isCompany);

  const email = watch('email');
  const message = watch('message');
  const messageLength = message?.length || 0;
  const maxMessageLength = 255;

  const selectedPaymentMethod = watch('payment_method');

  const amount = parseCurrency(watch('amount')) || 0;
  const productAmount = selectedProducts.reduce((amount, product) => amount + product.selling_price * productQuantities[product.id], 0);
  const donationAmount = amount
    + selectedProducts.reduce((amount, product) => amount + (product.selling_price - product.cost_price) * productQuantities[product.id], 0)
    + requiredProducts.reduce((amount, product) => amount + (product.selling_price - product.cost_price), 0);
  const charityFee = requiredProducts.reduce((amount, product) => amount + product.selling_price, 0);
  /** The fee is 15% of the total amount, with a maximum of € 0,95. */
  const platformFee = Math.min(95, Math.round((amount + productAmount + charityFee) * 0.15));
  const totalAmount = amount + productAmount + charityFee + platformFee;

  const [donate, { loading: donating, errors: serverErrors }] = useMutation(routes.donate(campaign.id), {
    onError: (errors) => {
      setServerErrors(errors, setError, defaultValues);
    },
    onSuccess: (result) => {
      const props = result.props as unknown as DonationPageProps;

      if (props.continueUrl) {
        // Redirect the user to the payment screen, breaking out of the embed iframe.
        // The user can go browse back from the payment screen because the redirection
        // is handled in the onSuccess callback, which is not executed again on browsing back.
        window.parent.location = props.continueUrl;
      }
    },
  });

  const submit = (data: typeof defaultValues) => {
    donate({
      amount: data?.amount ? parseCurrency(data.amount) : null,
      email: data?.email,
      email_opt_in: data?.email_opt_in,
      message: data?.message,
      first_name: data?.first_name,
      last_name: data?.last_name,
      company_name: isCompany ? data?.company_name : null,
      hide_name: data?.hide_name,
      country: data?.country,
      street: data?.street,
      house_number: data?.house_number,
      extra_address_line: data?.extra_address_line,
      zip_code: data?.zip_code,
      city: data?.city,
      payment_method: data?.payment_method,
      payment_method_issuer: data?.payment_method_issuer,
      embed_url: embedUrl,
      purchases: selectedProducts.map((product) => ({
        product_id: product.id,
        quantity: productQuantities[product.id],
      })),
    });
  };

  const [showPlatformDescription, setShowPlatformDescription] = useState(false);

  return (
    <>
      <Head title={campaign.document_title} />

      <NavBar charity={charity} showLogin={false} />

      <Header>
        <Container>
          {(!campaign.enabled || !campaign.project.enabled) && (
            <PageOfflineWarning />
          )}

          <Breadcrumb href={routes.campaign_page(campaign.id)}>
            {campaign.title}
          </Breadcrumb>

          <div className="leading-tight">
            <h1 className={classNames('text-2xl font-medium text-slate-800')}>
              <Trans i18nKey="frontend:donate_title" values={{ campaign: campaign.title }}>
                <strong />
              </Trans>
            </h1>
            <div className="text-slate-500">
              <Delimit>
                {(campaign.type === 'individual' || campaign.company_name) && campaign.campaigner !== campaign.title && campaign.campaigner}
                {charity.title}
              </Delimit>
            </div>
          </div>
        </Container>
      </Header>

      <Container>
        <form onSubmit={handleSubmit(submit)}>
          <div className="space-y-8">
            {serverErrors && <ServerErrors errors={serverErrors} defaultValues={defaultValues} scrollIntoView />}

            <div className="space-y-6">
              <h2 className="flex items-center text-base">
                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-project/30">
                  <span className="text-sm font-bold text-black opacity-50">
                    1
                  </span>
                </div>
                <span className="ml-4 font-medium text-slate-500">
                  {t('frontend:your_donation')}
                </span>
              </h2>

              <InputGroup>
                <InputLabel valid={!errors.amount} required={totalProductsQuantity === 0} htmlFor="donation_amount">
                  {t('frontend:amount')}
                </InputLabel>
                <InputDescription>
                  {t('frontend:donation_amount_description', { campaign: campaign.title })}
                </InputDescription>
                <InputWrapper>
                  <label className="text-2xl ml-4 !px-0 font-bold" htmlFor="donation_amount">
                    €
                  </label>
                  <Input
                    {...register('amount', { required: totalProductsQuantity === 0 })}
                    id="donation_amount"
                    className={classNames(
                      'pl-10 font-bold !h-16 text-2xl placeholder:font-normal',
                    )}
                    onBlur={(event) => {
                      handleCurrencyBlur(event);
                      setValue('amount', event.target.value);
                    }}
                    aria-invalid={!!errors.amount}
                    inputMode="decimal"
                    placeholder={totalProductsQuantity > 0 ? formatInputCurrency(0) : undefined}
                    min={totalProductsQuantity === 0 ? 50 : undefined}
                  />
                </InputWrapper>
                <div className="mt-2 space-x-2">
                  <Button
                    onClick={() => setValue('amount', formatInputCurrency(500))}
                    variant="tertiary"
                    className="!bg-slate-100"
                    size="sm"
                  >
                    {formatCurrency(500, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </Button>
                  <Button
                    onClick={() => setValue('amount', formatInputCurrency(1000))}
                    variant="tertiary"
                    className="!bg-slate-100"
                    size="sm"
                  >
                    {formatCurrency(1000, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </Button>
                  <Button
                    onClick={() => setValue('amount', formatInputCurrency(2500))}
                    variant="tertiary"
                    className="!bg-slate-100"
                    size="sm"
                  >
                    {formatCurrency(2500, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </Button>
                  <Button
                    onClick={() => setValue('amount', formatInputCurrency(5000))}
                    variant="tertiary"
                    className="!bg-slate-100"
                    size="sm"
                  >
                    {formatCurrency(5000, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </Button>
                  <Button
                    onClick={() => setValue('amount', formatInputCurrency(10000))}
                    variant="tertiary"
                    className="!bg-slate-100"
                    size="sm"
                  >
                    {formatCurrency(10000, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </Button>
                </div>
                <ErrorMessage error={errors.amount} attribute={t('frontend:amount')} />
              </InputGroup>

              {activeProducts.length > 0 && (
                <InputGroup>
                  <InputLabel>
                    {t('frontend:extras')}
                  </InputLabel>
                  <div className="space-y-3">
                    {activeProducts.map((product) => (
                      <div
                        className={classNames(
                          'border rounded-lg transition-colors',
                          productQuantities[product.id] > 0 ? 'border-slate-250 border-2 p-[23px]' : 'border-slate-200 p-[24px]',
                        )}
                        key={product.id}
                      >
                        <div
                          className={classNames(
                            product.image_url && 'grid gap-4 grid-cols-[80px_1fr]',
                            product.max_per_order === 0 && 'opacity-60',
                          )}
                        >
                          {product.image_url && (
                            <div className="w-[80x] h-[80px]">
                              <img src={product.image_url} alt={product.title} className="rounded w-full h-full object-cover" />
                            </div>
                          )}
                          <div>
                            <h3>
                              {product.title}
                            </h3>

                            {product.public_description && (
                              <HTML value={product.public_description} />
                            )}

                            <div className="mt-1 font-medium">
                              {formatCurrency(product.selling_price)}
                            </div>

                            {product.selling_price > product.cost_price && (
                              <div className="mt-1 text-sm text-emerald-600">
                                {product.cost_price > 0 ? t('frontend:cost_price_description', {
                                  donationAmount: formatCurrency(product.selling_price - product.cost_price),
                                }) : t('frontend:no_cost_price_description')}
                              </div>
                            )}

                            <div className="flex mt-3 space-x-1">
                              {product.max_per_order > 0 && (
                                <>
                                  <Button
                                    onClick={() => removeProduct(product)}
                                    variant="secondary"
                                    size="sm"
                                    className="!px-2"
                                    disabled={!productQuantities[product.id]}
                                    title={t('frontend:remove_name', { name: product.title })}
                                  >
                                    <Icon>
                                      <Minus />
                                    </Icon>
                                  </Button>
                                  <Input
                                    value={productQuantities[product.id] || ''}
                                    onChange={(event) => handleProductQuantityChange(event, product)}
                                    onBlur={(event) => handleIntegerBlur(event)}
                                    inputSize="sm"
                                    className={classNames(
                                      '!w-[42px] !px-2 text-center',
                                      productQuantities[product.id] && 'font-medium'
                                    )}
                                    inputMode="decimal"
                                    max={product.max_per_order}
                                    maxLength={2}
                                    placeholder="0"
                                    aria-label={t('frontend:quantity_name', { name: product.title })}
                                  />
                                  <Button
                                    onClick={() => addProduct(product)}
                                    variant="secondary"
                                    size="sm"
                                    className="!px-2"
                                    disabled={productQuantities[product.id] >= product.max_per_order}
                                    title={t('frontend:add_name', { name: product.title })}
                                  >
                                    <Icon>
                                      <Plus />
                                    </Icon>
                                  </Button>
                                </>
                              )}
                              {product.max_per_order === 0 && (
                                <div className="bg-slate-100 px-1 rounded-sm text-sm">
                                  {t('frontend:sold_out')}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </InputGroup>
              )}
            </div>

            <hr className="border-t-2" />

            <div className="space-y-6">
              <h2 className="flex items-center text-base">
                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-project/30">
                  <span className="text-sm font-bold text-black opacity-50">
                    2
                  </span>
                </div>
                <span className="ml-4 font-medium text-slate-500">
                  {requirePersonalDetails ? t('frontend:your_details') : t('frontend:your_details_optional')}
                </span>
              </h2>

              <InputGroup>
                <InputLabel valid={!errors.first_name} required={requirePersonalDetails} htmlFor="donation_first_name">
                  {t('frontend:name')}
                </InputLabel>
                <FadeIn
                  when={!requirePersonalDetails}
                  render={() => (
                    <InputDescription>
                      {t('frontend:donation_name_description')}
                    </InputDescription>
                  )}
                />
                <div className="grid grid-cols-[2fr_3fr] gap-2">
                  <div>
                    <Input
                      {...register('first_name', { required: requirePersonalDetails })}
                      id="donation_first_name"
                      aria-invalid={!!errors.first_name}
                      placeholder={t('frontend:first_name')}
                    />
                  </div>
                  <div>
                    <Input
                      {...register('last_name', { required: requirePersonalDetails })}
                      id="donation_last_name"
                      aria-invalid={!!errors.last_name}
                      placeholder={t('frontend:last_name')}
                    />
                  </div>
                </div>
                <ErrorMessage error={errors.first_name} attribute={t('frontend:first_name')} />
                <ErrorMessage error={errors.last_name} attribute={t('frontend:last_name')} />
              </InputGroup>

              <InputGroup>
                <InputLabel valid={!errors.email} required={requirePersonalDetails} htmlFor="donation_email">
                  {t('frontend:email_address')}
                </InputLabel>
                <FadeIn
                  when={!requirePersonalDetails}
                  render={() => (
                    <InputDescription>
                      {t('frontend:donation_email_description', { name: campaign.campaigner })}
                    </InputDescription>
                  )}
                />
                <Input
                  {...register('email', { required: requirePersonalDetails })}
                  id="donation_email"
                  aria-invalid={!!errors.email}
                />
                <ErrorMessage error={errors.email} attribute={t('frontend:email_address')} />
              </InputGroup>

              <InputGroup>
                <CheckboxLabel htmlFor="donation_company_toggle" >
                  <Checkbox
                    checked={isCompany}
                    onChange={toggleCompany}
                    id="donation_company_toggle"
                    className="mt-1"
                  />
                  <span>
                    {t('frontend:donate_as_company')}
                  </span>
                </CheckboxLabel>
              </InputGroup>

              <FadeIn
                when={isCompany}
                render={() => (
                  <InputGroup>
                    <InputLabel
                      valid={!errors.company_name}
                      htmlFor="donation_company_name"
                    >
                      {t('frontend:company_name')}
                    </InputLabel>
                    <InputDescription>
                      {t('frontend:donate_as_company_description')}
                    </InputDescription>
                    <Input
                      {...register('company_name')}
                      id="donation_company_name"
                      aria-invalid={!!errors.company_name}
                    />
                    <ErrorMessage error={errors.company_name} attribute={t('frontend:company_name')} />
                  </InputGroup>
                )}
              />

              <FadeIn
                when={requirePersonalDetails}
                render={() => (
                  <AddressInputs form={form} required />
                )}
              />

              <InputGroup>
                <InputLabel valid={!errors.message} htmlFor="donation_message">
                  {t('frontend:donation_message')}
                </InputLabel>
                <InputDescription>
                  {t('frontend:donation_message_description', { name: campaign.campaigner })}
                  {' '}
                  <span className={classNames('bg-slate-200 text-slate-700 px-1 inline-block text-sm rounded-sm', messageLength === 0 && 'opacity-50')}>
                    <strong>{messageLength}</strong> / {maxMessageLength}
                  </span>
                </InputDescription>
                <Textarea
                  {...register('message')}
                  id="donation_message"
                  rows={3}
                  aria-invalid={!!errors.message}
                  maxLength={255}
                />
                <ErrorMessage error={errors.message} attribute={t('frontend:donation_message')} />
              </InputGroup>

              {(requirePersonalDetails || !!email) && (
                <div>
                  <CheckboxLabel htmlFor="donation_hide_name">
                    <Checkbox
                      {...register('hide_name')}
                      id="donation_hide_name"
                      className="mt-1"
                    />
                    <span>
                      {isCompany ? t('frontend:hide_name_text_company') : t('frontend:hide_name_text')}
                    </span>
                  </CheckboxLabel>

                  <FadeIn
                    when={requirePersonalDetails || !!email}
                    render={() => (
                      <CheckboxLabel htmlFor="donation_email_opt_in">
                        <Checkbox
                          {...register('email_opt_in')}
                          id="donation_email_opt_in"
                          className="mt-1"
                        />
                        <span>
                          {t('frontend:email_opt_in_text', { charity: charity.title })}
                        </span>
                      </CheckboxLabel>
                    )}
                  />
                </div>
              )}

              <FadeIn
                when={requirePersonalDetails}
                render={() => (
                  <InfoMessage>
                    {shareDetailsWithCampaigner ? t('frontend:donation_personal_details_are_shared', {
                      campaigner: campaign.campaigner,
                    }) : (
                      <Trans i18nKey="frontend:donation_personal_details_not_shared" values={{ campaigner: campaign.campaigner }}>
                        <strong />
                      </Trans>
                    )}
                  </InfoMessage>
                )}
              />
            </div>

            <hr className="border-t-2" />

            <div className="space-y-6">
              <h2 className="flex items-center text-base">
                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-project/30">
                  <span className="text-sm font-bold text-black opacity-50">
                    3
                  </span>
                </div>
                <span className="ml-4 font-medium text-slate-500">
                  {t('frontend:payment')}
                </span>
              </h2>

              {!canReceivePayments && (
                <FadeIn className="p-6 border rounded-lg border-orange-500/20 bg-orange-50">
                  {t('frontend:no_payment_methods', { charity: charity.title })}
                </FadeIn>
              )}

              {canReceivePayments && (
                <>
                  <InputGroup>
                    <InputLabel valid={!errors.payment_method} required htmlFor="donation_payment_method_0">
                      {t('frontend:payment_method')}
                    </InputLabel>

                    <div className="space-y-2">
                      {supportedPaymentMethods.map((paymentMethod, i) => (
                        <Fragment key={paymentMethod.id}>
                          <RadioBox size="lg">
                            <Radio
                              {...register('payment_method', { required: true })}
                              value={paymentMethod.id}
                              aria-invalid={!!errors.payment_method}
                              id={`donation_payment_method_${i}`}
                              className="mt-[3px] mr-4"
                            />
                            <div className="w-full">
                              <div className="flex">
                                <img src={getPaymentMethodLogo(paymentMethod.id)} className="mr-2" />
                                {t(`shared:payment_method.${paymentMethod.id}`)}
                              </div>
                              <FadeIn
                                when={selectedPaymentMethod === paymentMethod.id && paymentMethod.issuers.length > 0}
                                className="w-full mt-3"
                                render={() => (
                                  <>
                                    <Select
                                      {...register('payment_method_issuer', { required: true })}
                                      aria-invalid={!!errors.payment_method_issuer}
                                    >
                                      <option value="">{t('shared:payment_method.make_a_choice')}</option>
                                      {paymentMethod.issuers.map((issuer) => (
                                        <option value={issuer.id} key={issuer.id}>
                                          {issuer.name}
                                        </option>
                                      ))}
                                    </Select>
                                    <ErrorMessage attribute={t('frontend:payment_method')} error={errors.payment_method_issuer} />
                                  </>
                                )}
                              />
                            </div>
                          </RadioBox>
                        </Fragment>
                      ))}
                    </div>

                    <ErrorMessage attribute={t('frontend:payment_method')} error={errors.payment_method} />
                  </InputGroup>

                  <InfoMessage variant={email ? 'success' : 'alert'}>
                    <strong>
                      {email ? t('frontend:will_receive_payment_receipt') : t('frontend:will_not_receive_payment_receipt')}
                    </strong>
                    <div>
                      {email
                        ? t('frontend:will_receive_payment_receipt_description')
                        : t('frontend:will_not_receive_payment_receipt_description')}
                    </div>
                  </InfoMessage>
                </>
              )}
            </div>

            <table className="w-full">
              <tbody>
                <tr>
                  <td className="w-3/4">
                    {t('frontend:donation')}
                  </td>
                  <td className={classNames('text-right', !amount && totalProductsQuantity === 0 && 'text-slate-500')}>
                    {formatCurrency(amount)}
                  </td>
                </tr>
                {selectedProducts.map((product) => productQuantities[product.id] > 0 && (
                  <tr key={product.id}>
                    <td>
                      {productQuantities[product.id] > 1 && `${formatNumber(productQuantities[product.id])}× `}
                      {product.title}
                    </td>
                    <td className="text-right">
                      {formatCurrency(product.selling_price * productQuantities[product.id])}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <a
                      onClick={() => setShowPlatformDescription((show) => !show)}
                      className="text-slate-900 hover:text-slate-600"
                      role="button"
                    >
                      {t('frontend:fee')}
                      <Icon className="ml-2">
                        <HelpCircle />
                      </Icon>
                    </a>
                  </td>
                  <td className={classNames('text-right', !amount && totalProductsQuantity === 0 && 'text-slate-500')}>
                    {formatCurrency(platformFee + charityFee)}
                  </td>
                </tr>
                <tr className="font-bold">
                  <td>
                    {t('frontend:amount_due')}
                  </td>
                  <td className={classNames('text-right', !amount && totalProductsQuantity === 0 && 'text-slate-500')}>
                    {formatCurrency(totalAmount)}
                  </td>
                </tr>
              </tbody>
            </table>

            <FadeIn
              when={showPlatformDescription}
              render={() => (
                <InfoMessage>
                  <h5 className="font-bold">
                    {t('frontend:platform_fee_title')}
                  </h5>
                  <p>
                    {t('frontend:platform_fee_explanation', { platformFee: formatCurrency(platformFee) })}
                  </p>
                  {requiredProducts.length > 0 && (
                    <>
                      <p className="mt-4">
                        {t('frontend:charity_fee_description', { charity: charity.title })}
                      </p>
                      <ul className="list-disc pl-4 mt-4">
                        {requiredProducts.map((product) => (
                          <li className="whitespace-pre-line" key={product.id}>
                            <strong>
                              {t('frontend:charity_fee_product_description', {
                                product: product.title,
                                amount: formatCurrency(product.selling_price),
                              })}
                            </strong>
                            {product.public_description && (
                              <HTML value={product.public_description} />
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </InfoMessage>
              )}
            />

            {productWithTerms.length > 0 && (
              <InputGroup>
                <CheckboxLabel htmlFor="donation_terms">
                  <Checkbox
                    {...register('agree_to_terms', { required: true })}
                    id={'donation_terms'}
                    className="mt-1"
                    aria-invalid={!!errors.agree_to_terms}
                  />
                  <span>
                    {t('frontend:agree_to_terms_for')}
                    {' '}
                    <Delimit delimiter=", ">
                      {productWithTerms.map((product) => (
                        <a href={product.terms_url || ''} target="_blank" rel="noopener noreferrer" key={product.id}>
                          {product.title}
                        </a>
                      ))}
                    </Delimit>
                  </span>
                </CheckboxLabel>
                <ErrorMessage
                  error={errors.agree_to_terms}
                  message={errors.agree_to_terms ? t('frontend:agree_to_terms_to_continue') : null}
                />
              </InputGroup>
            )}

            <div>
              <Button
                type="submit"
                loading={donating}
                className="block w-full"
                size="lg"
                disabled={!canReceivePayments || amount + productAmount === 0}
              >
                <Icon className="mr-2">
                  <Lock />
                </Icon>
                {t('frontend:donate_now')}
              </Button>
              <ErrorMessage message={errors.root?.payment_failed?.message} />
            </div>

            <div className="p-4 bg-project/5 flex items-center space-x-3 rounded-lg">
              {charity.logo_url && (
                <div className="h-12 max-w-16">
                  <img src={charity.logo_url} className="block w-full h-full object-contain" />
                </div>
              )}
              <div className="whitespace-pre-line">
                <Trans i18nKey="frontend:donation_goes_to" values={{
                  charity: charity.title,
                  campaign: campaign.title,
                  amount: formatCurrency(donationAmount),
                }}>
                  <strong />
                </Trans>
              </div>
            </div>

          </div>
        </form>
      </Container>
    </>
  );
}

DonationPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
