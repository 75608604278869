import { Link } from '@inertiajs/react';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import { ReactNode, useState } from 'react';
import { Download, HelpCircle } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { setServerErrors, useMutation } from '../../Shared/api';
import { useLocale } from '../../Shared/locale';
import { useNotifier } from '../../Shared/Notifier/NotificationProvider';
import { Campaign, Donation, PaymentStatus, Product, Project, Purchase } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import ErrorMessage from '../../Shared/UI/ErrorMessage';
import { FadeIn } from '../../Shared/UI/FadeIn';
import { Icon } from '../../Shared/UI/Icon';
import InfoMessage from '../../Shared/UI/InfoMessage';
import { Input } from '../../Shared/UI/Input';
import InputDescription from '../../Shared/UI/InputDescription';
import InputGroup from '../../Shared/UI/InputGroup';
import InputLabel from '../../Shared/UI/InputLabel';
import ServerErrors from '../../Shared/UI/ServerErrors';
import { Textarea } from '../../Shared/UI/Textarea';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { useRouter } from '../router';
import { SharedProps } from '../types';
import { AButton, Button } from '../UI/Button';
import Container from '../UI/Container';
import Layout from '../UI/Layout';

interface BillingPageProps extends SharedProps {
  donation: Donation & {
    campaign: Campaign & {
      project: Project;
    };
    token: string;
    company_name: string | null;
    billing_info: string | null;
    service_fee_amount: number;
    service_fee_vat_amount: number;
    donation_invoice_url: string;
    fee_invoice_url: string;
    status_url: string | null;
    purchases: (Purchase & {
      product: Product;
    })[];
  };
}

export default function DonationPage({
  donation,
  charity,
}: BillingPageProps) {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { formatCurrency, formatDate, formatNumber } = useLocale();
  const notifier = useNotifier();

  useCharityStyle(charity);

  const { register, handleSubmit, setError, formState: { errors, defaultValues }, watch } = useForm({
    defaultValues: {
      company_name: donation.company_name,
      billing_info: donation.billing_info,
    },
  });

  const billingInfo = watch('billing_info');

  const [editBillingInfo, { loading, errors: serverErrors }] = useMutation(routes.edit_billing_info(donation.id, donation.token), {
    onSuccess: () => {
      notifier.notify(t('frontend:saved_billing_info'));
    },
    onError: (errors) => {
      setServerErrors(errors, setError, defaultValues);
    },
  });

  const submit = (data: typeof defaultValues) => {
    editBillingInfo(data);
  };

  const [showPlatformDescription, setShowPlatformDescription] = useState(false);

  const pending = donation.payment_status === PaymentStatus.PENDING;
  const paid = donation.payment_status === PaymentStatus.PAID;
  const expired = donation.payment_status === PaymentStatus.EXPIRED;

  const purchases = sortBy(donation.purchases, [(purchase) => !purchase.product.is_campaign_product, 'product.required', 'product.title']);
  const platformFee = donation.service_fee_amount + donation.service_fee_vat_amount;
  const totalAmount = donation.amount + platformFee + sumBy(purchases, 'amount');

  return (
    <Container>
      <div className="space-y-4">
        <div className="flex justify-between">
          <h1 className="mr-2 text-3xl">
            {t('frontend:your_donation')}
          </h1>
          {charity.logo_url && (
            <img src={charity.logo_url} alt={charity.title} className="block h-8 srhink-0" />
          )}
        </div>

        <Card panels border shadow={false}>
          <div className="space-y-4">
            <div>
              <h4 className="font-medium text-slate-500">
                {t('frontend:campaign')}
              </h4>
              <Link href={donation.campaign.url}>
                {donation.campaign.title}
              </Link>
            </div>
            <div>
              <h4 className="font-medium text-slate-500">
                {t('frontend:charity')}
              </h4>
              <Link href={charity.url}>
                {charity.title}
              </Link>
            </div>
            <div>
              <h4 className="font-medium text-slate-500">
                {t('frontend:invoice_number')}
              </h4>
              {donation.donation_invoice_id}
            </div>
            <div>
              <h4 className="font-medium text-slate-500">
                {t('frontend:date')}
              </h4>
              {formatDate(donation.created_at, 'display_date_time')}
            </div>
          </div>
          <div className="space-y-4">
            <h4 className="font-medium text-slate-500">
              {t('frontend:payment')}
            </h4>
            {pending && (
              <div className="p-4 border rounded-lg border-orange-500/20 bg-orange-50">
                <h5 className="font-bold">
                  {t('frontend:payment_not_completed')}
                </h5>
                {t('frontend:payment_not_completed_description')}
              </div>
            )}
            {expired && (
              <div className="p-4 bg-white border border-red-500 rounded-lg">
                <h5 className="font-bold">
                  {t('frontend:payment_has_expired')}
                </h5>
                {t('frontend:payment_has_expired_description')}
              </div>
            )}
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="w-3/4">
                    {t('frontend:donation')}
                  </td>
                  <td className={classNames('text-right')}>
                    {formatCurrency(donation.amount)}
                  </td>
                </tr>
                {purchases.map((purchase) => (
                  <tr key={purchase.id}>
                    <td className="w-3/4">
                      {!purchase.product.required && `${formatNumber(purchase.quantity)}× `}
                      {purchase.product.title}
                    </td>
                    <td className={classNames('text-right')}>
                      {!purchase.product.is_campaign_product && formatCurrency(purchase.amount)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <a
                      onClick={() => setShowPlatformDescription((show) => !show)}
                      className="text-slate-900 hover:text-slate-600"
                      role="button"
                    >
                      {t('frontend:platform_fee')}
                      <Icon className="ml-2">
                        <HelpCircle />
                      </Icon>
                    </a>
                  </td>
                  <td className={classNames('text-right')}>
                    {formatCurrency(platformFee)}
                  </td>
                </tr>
                <tr className="font-bold">
                  <td>
                    {paid ? t('shared:payment_status.paid') : t('frontend:amount_due')}
                  </td>
                  <td className={classNames('text-right')}>
                    {formatCurrency(totalAmount)}
                  </td>
                </tr>
              </tbody>
            </table>

            {showPlatformDescription && (
              <FadeIn>
                <InfoMessage>
                  <h5 className="font-bold">
                    {t('frontend:platform_fee_title')}
                  </h5>
                  {t('frontend:platform_fee_explanation')}
                </InfoMessage>
              </FadeIn>
            )}

            {pending && donation.status_url && (
              <AButton
                href={donation.status_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('frontend:pay')}
              </AButton>
            )}
          </div>
        </Card>
      </div>

      {(paid || pending) && (
        <>
          <hr />

          <div className="space-y-4">
            <div>
              <h2 className="mb-2 text-2xl">
                {t('frontend:download_your_invoice')}
              </h2>
              {t('frontend:invoice_description', { charity: charity.title })}
            </div>

            <div>
              <AButton
                href={donation.donation_invoice_url}
                variant="secondary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon className="mr-2">
                  <Download />
                </Icon>
                {t('frontend:donation')}
              </AButton>
              {' '}
              <AButton
                href={donation.fee_invoice_url}
                variant="secondary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon className="mr-2">
                  <Download />
                </Icon>
                {t('frontend:platform_fee')}
              </AButton>
            </div>

            <form onSubmit={handleSubmit(submit)}>
              <Card className="!bg-slate-50" border shadow={false}>
                <div className="space-y-6">
                  {serverErrors && <ServerErrors errors={serverErrors} defaultValues={defaultValues} scrollIntoView />}

                  <InputGroup>
                    <InputLabel valid={!errors.company_name} htmlFor="company_name">
                      {t('frontend:company_name')}
                    </InputLabel>
                    <Input
                      {...register('company_name')}
                      id="company_name"
                      aria-invalid={!!errors.company_name}
                    />
                    <ErrorMessage error={errors.company_name} attribute={t('frontend:company_name')} />
                  </InputGroup>

                  <InputGroup>
                    <InputLabel valid={!errors.billing_info} htmlFor="billing_info">
                      {t('frontend:billing_info')}
                    </InputLabel>
                    <InputDescription>
                      {t('frontend:billing_info_description')}
                    </InputDescription>
                    <Textarea
                      {...register('billing_info')}
                      id="billing_info"
                      aria-invalid={!!errors.billing_info}
                      rows={Math.max((billingInfo || '').split('\n').length, 4)}
                    />
                    <ErrorMessage error={errors.billing_info} attribute={t('frontend:billing_info')} />
                  </InputGroup>

                  <Button
                    type="submit"
                    loading={loading}
                    variant="secondary"
                  >
                    {t('frontend:save')}
                  </Button>
                </div>
              </Card>
            </form>
          </div>
        </>
      )}
    </Container>
  );
}

DonationPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
