import { Charity, Event, Project } from '../../Shared/types';

export default function ProjectLogo({
  project,
  charity,
}: {
  project: Project & {
    event: Event | null;
  };
  charity: Charity;
}) {
  const thumbnailUrl = project.thumbnail_url || charity.thumbnail_url;
  const logoUrl = project.event?.logo_url;

  return (
    <div className="flex-shrink-0 h-8 aspect-video">
      {thumbnailUrl && (
        <img src={thumbnailUrl} alt={project.title} className="block object-contain w-full h-full rounded" />
      )}
      {!thumbnailUrl && logoUrl && (
        <div className="w-full h-full p-0.5 border rounded">
          <img src={logoUrl} alt={project.title} className="block object-contain w-full h-full rounded" />
        </div>
      )}
      {!thumbnailUrl && !logoUrl && (
        <span className="block h-8 aspect-video rounded leading-[2rem] text-center bg-project/20 text-black/40">
          {project.title.slice(0, 1)}
        </span>
      )}
    </div>
  );
}
