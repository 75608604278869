import { Calendar, Globe, MapPin } from 'react-feather';

import { Event } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import DateRange from '../../Shared/UI/DateRange';
import { Icon } from '../../Shared/UI/Icon';

export interface EventCardProps {
  event: Event;
}

export default function EventCard({
  event,
}: EventCardProps) {
  return (
    <Card
      spacing="xs"
      border
      link={event.website ? {
        href: event.website,
        blank: true,
      } : undefined}
    >
      <div className="flex items-center space-x-4">
        {event.logo_url && (
          <div className="shrink-0">
            <div className="w-[96px] h-[96px] shadow bg-white border rounded-full flex items-center justify-center">
              <img src={event.logo_url} className="block max-h-[80px] max-w-[80px] rounded-full" />
            </div>
          </div>
        )}
        <div className="min-w-0">
          <h3 className="overflow-hidden whitespace-nowrap text-ellipsis">
            {event.title}
          </h3>
          <ul className="font-medium text-slate-500">
            <li className="flex gap-2">
              <Icon className="mt-1">
                <Calendar />
              </Icon>
              <span>
                <DateRange start={event.start_date} end={event.end_date} />
              </span>
            </li>
            {event.location && (event.location.id || event.location.description) && (
              <li className="flex gap-2">
                <Icon className="mt-1">
                  <MapPin />
                </Icon>
                <span>
                  {event.location.description.split(',')[0]}
                </span>
              </li>
            )}
            {event.website && (
              <li className="flex gap-2">
                <Icon className="mt-1">
                  <Globe />
                </Icon>
                <span>
                  {getUrlWithoutPrefix(event.website)}
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </Card>
  );
}

/**
 * Strips http(s)://, www., trailing slash, and query parameters from a URL.
 */
const getUrlWithoutPrefix = (url: string) => (
  url.replace(/^https?:\/\//i, '').replace(/^www\./i, '').split('?')[0].replace(/\/$/, '')
);
