import { Head, usePage } from '@inertiajs/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '../../Shared/UI/Card';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { PageProps } from '../types';
import Container from '../UI/Container';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';

export default function CharityPage() {
  const { t } = useTranslation();
  const { charity, auth: { user } } = usePage<PageProps>().props;

  useCharityStyle(charity);

  return (
    <>
      <Head title={charity.title} />
      <NavBar charity={charity} user={user} />
      <Container>
        <div>
          <h1 className="mb-1 text-2xl">
            {t('frontend:privacy_statement')}
          </h1>
          <p>
            {t('frontend:privacy_intro', { charity: charity.title })}
          </p>
        </div>
        <Card className="whitespace-pre-line">
          {charity.privacy_statement || t('frontend:no_privacy_statement_set', { charity: charity.title })}
        </Card>
      </Container>
    </>
  );
}

CharityPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
