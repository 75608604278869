import { useLocale } from '../../Shared/locale';
import { Charity, Event, Project } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { useRouter } from '../router';
import ProjectLogo from './ProjectLogo';

export default function ProjectCard({
  project,
  charity,
}: {
  project: Project & {
    event: Event | null;
  };
  charity: Charity;
}) {
  const { formatCurrency } = useLocale();
  const { routes } = useRouter();

  return (
    <Card
      spacing="xs"
      link={{
        href: routes.project_page(project.id),
        ariaLabel: project.title,
      }}
      border
      className="flex items-center gap-3"
    >
      <ProjectLogo project={project} charity={charity} />
      <div className="min-w-0">
        <h4 className="overflow-hidden whitespace-nowrap text-ellipsis">
          {project.title}
        </h4>
      </div>
      <div className="ml-auto text-sm text-right whitespace-nowrap">
        {formatCurrency(project.funds_raised)}
      </div>
    </Card>
  );
}
