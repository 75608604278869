import { Head } from '@inertiajs/react';
import { ReactNode } from 'react';
import { ExternalLink } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useLocale } from '../../Shared/locale';
import { Charity, Event, Pagination, SharedProps } from '../../Shared/types';
import { Icon } from '../../Shared/UI/Icon';
import { Pager } from '../../Shared/UI/Pager';
import { SearchInput } from '../../Shared/UI/SearchInput';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import Container from '../UI/Container';
import Header from '../UI/Header';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';
import CharityCard from './CharityCard';
import EventHeader from './EventHeader';

export interface EventPageProps extends SharedProps {
  event: Event & {
    funds_raised: number;
    campaigns_count: number;
    charities_count: number;
  };
  charities: Pagination<Charity>;
}

export default function EventPage({
  event,
  charities,
  auth: {
    user,
  },
  params,
}: EventPageProps) {
  const { t } = useTranslation();
  const { formatCurrency, formatNumber } = useLocale();

  useCharityStyle();

  return (
    <>
      <Head title={event.title} />

      <NavBar user={user} />

      <Header>
        <Container>
          <EventHeader event={event} />
        </Container>

        <Container className="!pt-0">
          <hr className="border-slate-200" />
          <div className="grid grid-flow-col gap-2 auto-cols-auto">
            <div>
              <div className="text-lg font-medium">
                {formatCurrency(event.funds_raised, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
              </div>
              <span className="text-sm font-medium text-slate-600">
                {t('frontend:raised')}
              </span>
            </div>
            <div>
              <div className="text-lg font-medium">
                {formatNumber(event.campaigns_count)}
              </div>
              <span className="text-sm font-medium text-slate-600">
                {t('frontend:campaigns', { count: event.campaigns_count })}
              </span>
            </div>
            <div>
              <div className="text-lg font-medium">
                {formatNumber(event.charities_count)}
              </div>
              <span className="text-sm font-medium text-slate-600">
                {t('frontend:charities', { count: event.charities_count })}
              </span>
            </div>
          </div>
        </Container>
      </Header>

      <Container>
        {!event.ended && (
          <>
            <div className="space-y-4">
              <div>
                <h2 className="text-lg">
                  {t('frontend:start_campaign_with_supporta')}
                </h2>
                <p>
                  {t('frontend:select_charity_description')}
                </p>
              </div>

              {(params.search || charities.meta.last_page > 1) && (
                <div>
                  <SearchInput id="search" placeholder={t('frontend:find_your_charity')} />
                </div>
              )}

              <div className="space-y-2">
                {charities.data.map((charity) => (
                  <CharityCard charity={charity} event={event} key={charity.id} />
                ))}
              </div>

              {charities.data.length === 0 && (
                <div className="text-slate-500">
                  {t('frontend:no_results')}
                </div>
              )}

              {charities.meta.last_page > 1 && (
                <Pager data={charities} />
              )}
            </div>

            <div className="text-center">
              {t('frontend:already_have_campaign')}
              {' '}
              <a href="/me" className="font-medium">
                {t('frontend:log_in')}
              </a>
            </div>
          </>
        )}

        {event.ended && (
          <div className="text-slate-500">
            {t('frontend:creating_campaign_no_longer_possible')}
          </div>
        )}

        <hr className="border-slate-200" />

        <ul className="space-y-3 font-medium text-center">
          {!event.ended && (
            <li>
              <a
                href="https://supporta.cc/nl/goed-doel-aandragen"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('frontend:charity_missing')}
                <Icon className="ml-1.5">
                  <ExternalLink />
                </Icon>
              </a>
            </li>
          )}
          <li>
            <a
              href="https://supporta.cc/nl/helpdesk"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('frontend:frequently_asked_questions')}
              <Icon className="ml-1.5">
                <ExternalLink />
              </Icon>
            </a>
          </li>
        </ul>
      </Container>
    </>
  );
}

EventPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
