import parsePhoneNumber, { format } from 'libphonenumber-js';

import { useLocale } from '../locale';
import { Country } from '../types';

/**
 * Display the national format of a phone number if given country or the current
 * active project's country is equal to the country of the phone number.
 * Otherwise, display the international format of a phone number.
 *
 *  International: +31 88 366 5433
 *  National: 088 366 5433
 */
const PhoneNumber = ({
  phoneNumber,
  country,
}: {
  phoneNumber: string;
  country?: Country;
}) => {
  const { defaultCountry } = useLocale();
  const activeCountry = country || defaultCountry;

  /** Info is undefined if the phone number is invalid */
  const info = parsePhoneNumber(phoneNumber);

  if (info) {
    return <>{format(phoneNumber, activeCountry === info.country ? 'NATIONAL' : 'INTERNATIONAL')}</>;
  }

  return <>{phoneNumber}</>;
};

export default PhoneNumber;
