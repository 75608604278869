import { Check } from 'react-feather';

import { Icon } from './Icon';

interface ProgressCircleProps {
  isCompleted?: boolean;
}

export const ProgressCircle = ({ isCompleted }: ProgressCircleProps) => {
  const background = isCompleted ? 'bg-project' : 'bg-slate-200';

  return (
    <span className={`flex shrink-0 items-center justify-center w-6 h-6 rounded-full text-white ${background}`}>
      {isCompleted && (
        <Icon>
          <Check />
        </Icon>
      )}
    </span>
  );
};
