import { Head } from '@inertiajs/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { redirectParent } from '../../Shared/helpers';
import { Charity, Event, SharedProps } from '../../Shared/types';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import AddCampaignForm from '../Campaign/AddCampaignForm';
import { useRouter } from '../router';
import Breadcrumb from '../UI/Breadcrumb';
import Container from '../UI/Container';
import Header from '../UI/Header';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';
import EventHeader from './EventHeader';

export interface JoinEventPageProps extends SharedProps {
  event: Event;
  charity: Charity;
  redirect?: (url: string) => void;
}

export default function JoinEventPage({
  event,
  charity,
  auth: {
    user,
  },
  params,
  redirect = redirectParent,
}: JoinEventPageProps) {
  const { t } = useTranslation();
  const { routes } = useRouter();

  useCharityStyle(charity);

  return (
    <>
      <Head title={event.title} />

      <NavBar charity={charity} user={user} />

      <Header>
        <Container>
          <Breadcrumb href={params.return_url || routes.event_page(event.id)}>
            {event.title}
          </Breadcrumb>
          <div>
            <h2 className="mb-1 text-xl">
              {t('frontend:start_campaign_for_charity', { charity: charity.title })}
            </h2>
            {charity.description && (
              <p>
                {charity.description}
              </p>
            )}
          </div>

          <hr className="border-slate-200" />

          <EventHeader event={event} />
        </Container>
      </Header>

      <Container>
        <AddCampaignForm
          event={event}
          charity={charity}
          user={user}
          teams={[]}
          redirect={redirect}
        />
      </Container>
    </>
  );
}

JoinEventPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
