import { Head, usePage } from '@inertiajs/react';
import { ReactNode } from 'react';
import { ExternalLink } from 'react-feather';
import { useTranslation } from 'react-i18next';

import PhoneNumber from '../../Shared/Phone/PhoneNumber';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { PageProps } from '../types';
import Container from '../UI/Container';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';

export default function HelpPage() {
  const { t } = useTranslation();
  const { charity, auth: { user } } = usePage<PageProps>().props;

  useCharityStyle(charity);

  return (
    <>
      <Head title={charity.title} />
      <NavBar charity={charity} user={user} />
      <Container>
        <div>
          <h1 className="mb-1 text-2xl">
            {t('frontend:need_help')}
          </h1>
          <p>
            {t('frontend:need_help_description', { charity: charity.title })}
          </p>
        </div>

        <Card>
          <ul className="space-y-1">
            {charity.email && (
              <li>
                <span className="font-medium">{t('frontend:email')}:</span>
                {' '}
                <a href={`mailto:${charity.email}`}>{charity.email}</a>
              </li>
            )}
            {charity.phone && (
              <li>
                <span className="font-medium">{t('frontend:phone')}:</span>
                {' '}
                <a href={`tel:${charity.phone}`}>
                  <PhoneNumber phoneNumber={charity.phone} />
                </a>
              </li>
            )}
            {!charity.email && !charity.phone && (
              <li>
                {t('frontend:no_contact_details_set', { charity: charity.title })}
              </li>
            )}
          </ul>
        </Card>

        <div>
          <h2 className="mb-1 text-xl">
            {t('frontend:frequently_asked_questions')}
          </h2>
          <p>
            {t('frontend:frequently_asked_questions_description')}
            {' '}
            <a
              href="https://supporta.cc/nl/helpdesk"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('frontend:view_frequently_asked_questions')}
              <Icon className="ml-1.5">
                <ExternalLink />
              </Icon>
            </a>
          </p>
        </div>
      </Container>
    </>
  );
}

HelpPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
