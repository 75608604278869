import classNames from 'classnames';
import { useContext, useState } from 'react';
import { Edit, Share, X } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../Shared/Modal/Modal';
import { Campaign, Charity, Event, Project } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { ProgressCircle } from '../../Shared/UI/ProgressCircle';
import { useRouter } from '../router';
import { AButton, Button, LinkButton } from '../UI/Button';
import { EmbedContext } from '../UI/EmbedProvider';
import ShareOptions from './ShareOptions';

export function OnboardingSteps({
  campaign,
  charity,
  isLoggedInAsOwner,
}: {
  campaign: Campaign & {
    project: Project & {
      event: Event | null;
    };
    donations_count: number;
  };
  charity: Charity;
  isLoggedInAsOwner: boolean;
}) {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { isEmbedded } = useContext(EmbedContext);

  const { hasFinishedProfile, hasDonations } = getOnboardingStatus(campaign);

  const [showShareModal, setShowShareModal] = useState(false);

  return (
    <Card className="space-y-6">
      <div className="flex items-center justify-between">
        <h4 className="font-bold">
          {t('frontend:campaign_get_started')}
        </h4>
        {isLoggedInAsOwner && (
          <LinkButton href="?onboarding=0" variant="tertiary" size="sm" className="!bg-transparent text-xl !px-1.5">
            <Icon>
              <X />
            </Icon>
          </LinkButton>
        )}
      </div>

      <hr className="border-slate-200" />

      <div className="flex space-x-4">
        <span className="mt-0.5">
          <ProgressCircle isCompleted={hasFinishedProfile} />
        </span>
        <div className={classNames('w-full', hasFinishedProfile && 'text-slate-500')}>
          <strong className={classNames('text-lg', hasFinishedProfile && 'line-through')}>
            {t('frontend:onboarding_complete_campaign_title')}
          </strong>
          <div className="mb-4">
            {t('frontend:onboarding_complete_campaign_description')}
          </div>
          {!hasFinishedProfile && (
          // Can't seem to use an Inertia link here. Redirect to /login doesn't work.
            <AButton
              href={routes.edit_campaign(campaign.id)}
              className="w-full"
              target={isEmbedded ? '_blank' : undefined}
              rel={isEmbedded ? 'noreferrer noopener' : undefined}
            >
              <Icon className="mr-2">
                <Edit />
              </Icon>
              {t('frontend:onboarding_complete_campaign_cta')}
            </AButton>
          )}
        </div>
      </div>

      <div className="flex space-x-4">
        <span className="mt-0.5">
          <ProgressCircle isCompleted={hasDonations} />
        </span>
        <div className={classNames('w-full', hasDonations && 'text-slate-500')}>
          <strong className={classNames('text-lg', hasDonations && 'line-through')}>
            {t('frontend:onboarding_share_campaign_title', { name: campaign.first_name })}
          </strong>
          <div className="mb-4">
            {t('frontend:onboarding_share_campaign_description')}
          </div>
          <Button
            onClick={() => setShowShareModal(true)}
            variant="secondary"
            className="w-full"
          >
            <Icon className="mr-2">
              <Share />
            </Icon>
            {t('frontend:share')}
          </Button>
          <Modal isOpen={showShareModal} onClose={() => setShowShareModal(false)} size="sm">
            <ShareOptions campaign={campaign} charity={charity} />
          </Modal>
        </div>
      </div>
    </Card>
  );
}

export function getOnboardingStatus(campaign: Campaign & { donations_count: number; }) {
  const hasFinishedProfile = !!campaign.image_url && !!campaign.description;
  const hasDonations = campaign.donations_count > 0;

  return {
    hasFinishedProfile,
    hasDonations,
  };
}
